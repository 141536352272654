import { PortableText } from '@portabletext/react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import * as styles from './ReleaseDetails.module.scss';

type Props = {
    release: any;
}

const ReleaseDetails = ({ release }: Props) => (
    <div className={styles.wrapper}>
        <div className={styles.content}>
            <div className={styles.image}>
                <GatsbyImage image={release.coverArt.asset.gatsbyImageData} alt={`Cover art for ${release.title} by Ballistix`} />
            </div>
            <div className={styles.text}>
                <h1 className={styles.heading}>{release.title}</h1>
                <div className={styles.description}>
                    <PortableText value={release._rawReleaseNotes} />
                </div>
                <h2 className={styles.subheading}>Tracks</h2>
                <ol className={styles.tracks}>
                    {release.tracks?.map(track => (
                        <li key={track.title}>
                            <Link to={`/songs/${track.slug.current}/`} className={styles.track}>{track.title}</Link>
                        </li>
                    ))}
                </ol>
            </div>
        </div>
    </div>
)

export default ReleaseDetails