import React, { FunctionComponent } from 'react'
import { graphql, Link } from 'gatsby';
import { Release } from '../models/schema';
import { PortableText } from '@portabletext/react';
import {Helmet} from 'react-helmet';
import { GatsbyImage } from 'gatsby-plugin-image';
import Layout from '../components/Layout';
import PageContent from '../components/PageContent';
import ReleaseList from '../components/ReleaseList';
import Distributor from '../components/Distributor';
import * as styles from './ReleaseTemplate.module.scss';
import ReleaseDetails from '../components/ReleaseDetails';

type Props = {
    data: {
        release: any;
    }
}

const ReleaseTemplate: FunctionComponent<Props> = (props) => {
    const { release } = props.data;

    return (
        <Layout>
            <Helmet htmlAttributes={{lang: 'en'}}>
                <title>{release.title} {release.releaseType} by Ballistix</title>
            </Helmet>
            <ReleaseDetails release={release} />
            <PageContent>
                { release.streamingLinks && release.streamingLinks.length > 0 && (
                    <div className={styles.section}>
                        <h2 className={styles.subheading}>Stream {release.title} on:</h2>
                        <div className={styles.distributors}>
                            { release.streamingLinks?.map(({distributor, url}) => <Distributor key={distributor} href={url} id={distributor} />) }
                        </div>
                    </div>
                )}
                { release.downloadLinks && release.downloadLinks.length > 0 && (
                    <div className={styles.section}>
                        <h2 className={styles.subheading}>Purchase {release.title} from:</h2>
                        <div className={styles.distributors}>
                            { release.downloadLinks?.map(({distributor, url}) => <Distributor key={distributor} href={url} id={distributor} />) }
                        </div>
                    </div>
                )}
            </PageContent>
            <ReleaseList exclude={release.title} />
        </Layout>
    )
}

export default ReleaseTemplate

export const pageQuery = graphql`
	query($id: String!) {
		release: sanityRelease(_id: { eq: $id }) {
            coverArt {
                asset {
                    gatsbyImageData(width: 592, height: 592, placeholder: BLURRED)
                }
            }
            downloadLinks {
                distributor
                url
            }
            _rawReleaseNotes(resolveReferences: {maxDepth: 10})
            releaseType
            releaseDate(formatString: "yyyy")
            streamingLinks {
                distributor
                url
            }
            title
            tracks {
                title
                slug {
                    current
                }
            }
		}
	}
`
